import { UploadOutlined } from "@ant-design/icons";
import { Switch, Modal, Input, Radio, Select } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormItem from "../../../reusable/FormItem";
import { Toast } from "../../../reusable/Toast";
import { setDone, setLoading } from "../../../store/loading";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import { getErrorValue } from "../../../utils/getErrors";
import numeral from "../../../utils/numeral";

const initialValues = {
  transfer_time: "",
  image: null,
  catatan: "Penarikan saldo berhasil",
};

const validationSchema = (modal) => {
  // if (!isEmpty(modal) && modal.log.type_withdraw === 'manual') {
  // 	return Yup.object().shape({
  // 		transfer_time: Yup.string().required('Waktu Transfer wajib diisi'),
  // 	});
  // } return ;
};

function EditModal({
  modal,
  setModal,
  fetchRequestPaymentMethod,
  errors,
  onUpdateData,
}) {
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();

  const onClose = () => {
    setModal({});
  };

  const onChangeTypeAdminFeeBerbagilink = (e) => {
    let _modal = modal;
    _modal.log.type_admin_fee_berbagilink = e.target.value;
    setModal({ ..._modal });
  };

  const onChangeAdminFeeBerbagilink = (e) => {
    let _modal = modal;
    _modal.log.admin_fee_berbagilink = e.target.value;
    setModal({ ..._modal });
  };

  const onChangeProviderType = (value) => {
    let _modal = modal;
    _modal.log.provider_type = value;
    setModal({ ..._modal });
  };

  const onChangeStatus = (e) => {
    let _modal = modal;
    _modal.log.is_active = !_modal.log.is_active;
    setModal({ ..._modal });
  };

  return (
    <Modal
      onCancel={onClose}
      open={modal.type === "edit"}
      title="Detail Metode Pembayaran"
      onOk={() => onUpdateData()}
      okText="Simpan"
      destroyOnClose
    >
      {!isEmpty(modal) && (
        <div className="grid grid-cols-2 gap-y-2 items-center">
          <p className="font-bold text-lg">Nama:</p>
          <p className="text-lg justify-self-end">{modal.log.nama}</p>
          <p className="font-bold text-lg">Tipe Pembayaran:</p>
          <p className="text-lg justify-self-end">
            {modal.log.type_of_payment === "manual" ? "Manual" : "Otomatis"}
          </p>
          <p className="font-bold text-lg">Jenis Pembayaran:</p>
          <p className="text-lg justify-self-end">
            {modal.log.payment_method === "bank"
              ? "Bank Transfer"
              : modal.log.payment_method === "va"
              ? "Virtual Account"
              : modal.log.payment_method === "qris"
              ? "QRIS"
              : modal.log.payment_method === "e-wallet"
              ? "E-Wallet"
              : "-"}
          </p>
          <p className="font-bold text-lg">Nama Biller:</p>
          <p className="text-lg justify-self-end">
            {modal.log.biller_name === "mutasi-bank"
              ? "Mutasi Bank"
              : modal.log.biller_name === "bisabiller"
              ? "Bisabiller"
              : modal.log.biller_name
              ? modal.log.biller_name
              : "-"}
          </p>
          {!isEmpty(modal.log.biller_name) && (
            <p className="font-bold text-lg">Biaya Admin Channel:</p>
          )}
          {!isEmpty(modal.log.biller_name) && (
            <p className="text-lg justify-self-end">
              {modal.log.admin_fee_channel > 0 ? (
                modal.log.type_admin_fee_channel === "percentage" ? (
                  <span>{parseFloat(modal.log.admin_fee_channel)}% </span>
                ) : (
                  <span>
                    Rp.{" "}
                    {numeral(Math.ceil(modal.log.admin_fee_channel)).format(
                      "0,0"
                    )}{" "}
                  </span>
                )
              ) : (
                "-"
              )}
            </p>
          )}
          <p className="font-bold text-lg">Status:</p>
          <p className="text-lg justify-self-end">
            <Switch
              onChange={(val) => onChangeStatus(val)}
              defaultChecked={
                modal.log.is_active === 1 || modal.log.is_active === true
              }
            />
          </p>
        </div>
      )}
      {!isEmpty(modal) && !isEmpty(modal.log.biller_name) && (
        <div>
          <div>
            <div className="grid grid-cols-2 py-2 items-center">
              <p className="font-bold text-lg">Tipe Admin Berbagilink:</p>
              <div className="text-lg justify-self-end">
                <Radio.Group
                  onChange={onChangeTypeAdminFeeBerbagilink}
                  value={modal.log.type_admin_fee_berbagilink}
                >
                  <Radio value={"nominal"}>Nominal</Radio>
                  <Radio value={"percentage"}>Persentase</Radio>
                </Radio.Group>
              </div>
            </div>
            {errors && errors.type_admin_fee_berbagilink && (
              <p
                className="text-md text-red-600"
                style={{ textAlign: "right" }}
              >
                {errors.type_admin_fee_berbagilink[0]}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 py-2 items-center">
              <p className="font-bold text-lg">Nilai Admin Berbagilink:</p>
              <div className="text-lg justify-self-end">
                <Input
                  placeholder="Nominal/Persentase"
                  onChange={onChangeAdminFeeBerbagilink}
                  value={
                    modal.log.admin_fee_berbagilink
                      ? parseFloat(modal.log.admin_fee_berbagilink)
                      : null
                  }
                />
              </div>
            </div>
            {errors && errors.admin_fee_berbagilink && (
              <p
                className="text-md text-red-600"
                style={{ textAlign: "right" }}
              >
                {errors.admin_fee_berbagilink[0]}
              </p>
            )}
          </div>
        </div>
      )}
      {!isEmpty(modal) &&
        modal.log.type_of_payment == "otomatis" &&
        modal.log.biller_name == "bisabiller" && (
          <div>
            <div className="grid grid-cols-2 py-2 items-center">
              <p className="font-bold text-lg">Provider Type:</p>
              <div className="text-lg justify-self-end">
                {/* <Input
                  placeholder="Nominal/Persentase"
                  onChange={onChangeAdminFeeBerbagilink}
                  value={
                    modal.log.admin_fee_berbagilink
                      ? parseFloat(modal.log.admin_fee_berbagilink)
                      : null
                  }
                /> */}
                <Select
                  defaultValue={modal.log.provider_type}
                  style={{
                    width: 120,
                  }}
                  onChange={(value) => onChangeProviderType(value)}
                  options={[
                    {
                      value: "winpay",
                      label: "Winpay",
                    },
                    {
                      value: "flip",
                      label: "Flip",
                    },
                  ]}
                />
              </div>
            </div>
            {errors && errors.provider_type && (
              <p
                className="text-md text-red-600"
                style={{ textAlign: "right" }}
              >
                {errors.provider_type[0]}
              </p>
            )}
          </div>
        )}
    </Modal>
  );
}

export default EditModal;
